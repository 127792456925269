import SvgIcon from "@mui/material/SvgIcon";
import * as React from "react";

const SvgComponent = (props) => (
  <SvgIcon style={props.style} fontSize={props.fontSize}>
    <svg viewBox="0 0 845 845">
      <path d="M54.36 259.49l164.6 0 387.4 -174.56c15.09,-7.95 33.68,2.86 33.68,20.32l0 615.22c-0.24,16.39 -16.97,27.84 -32.38,20.9l-388.69 -175.14 -18.22 0 0 136.3c0,35.61 -28.75,61.85 -63.81,61.85 -33.96,0 -61.85,-27.88 -61.85,-61.85l0 -136.3 -20.73 0c-12.68,0 -22.97,-10.29 -22.97,-22.97l0 -260.8c0,-12.68 10.29,-22.97 22.97,-22.97zm738.4 303.06c21.35,21.35 -11.13,53.83 -32.48,32.48l-71.18 -71.18c-21.35,-21.35 11.13,-53.83 32.48,-32.48l71.18 71.18zm-71.18 -228.22c-21.35,21.35 -53.83,-11.13 -32.48,-32.48l71.18 -71.18c21.35,-21.34 53.83,11.13 32.48,32.48l-71.18 71.18zm-30.98 101.49c-30.21,0 -30.21,-45.93 0,-45.93l100.67 0c26.19,0 37.35,45.57 0,45.93l-100.67 0zm-535.79 130.41l-33.79 0 0 136.3c0,8.82 7.09,15.92 15.92,15.92 9.85,0 17.87,-5.45 17.87,-15.92l0 -136.3zm89.63 -267.94l0 229.13 349.67 157.55 0 -544.23 -349.67 157.55zm-45.93 7.13l-121.18 0 0 214.87 121.18 0 0 -214.87z" />
    </svg>
  </SvgIcon>
);

export default SvgComponent;
