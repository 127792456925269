import { Box, Typography } from "@mui/material";
import {FC} from "react";
import EntryFrame from "src/components/common/Dialog";
import useAppState from "src/hooks/useAppState";

const LoadingScreen: FC = () => {
  const [, actions] = useAppState();

  return (
    <EntryFrame poweredby>
      <Box sx={{ m: 2 }}>
        <Typography variant="h4" onClick={() => actions.signOut()}>
          410=350
        </Typography>
      </Box>
    </EntryFrame>
  );
};

export default LoadingScreen;

