import { Dispatch } from "react";
import { supabase } from "src/lib/supabase";
import { AppState, AppStateChange } from "src/state";

export const reducer = (state: AppState, action: AppStateChange) => {
  const s = { ...state.sso };

  switch (action.type) {
    /*
     * On SB session refresh, just fetch identity fro SB cache
     *
     */
    case "SetSSOSession": {
      if (action.value) {
        if (!s.hasSession) {
          s.hasSession = true;
          s.session = action.value;

          return { ...state, sso: s };
        }
      } else {
        s.hasSession = false;
        s.session = null;
        return { ...state, sso: s };
      }
      break;
    }
    case "ClearSSOSession": {
      s.hasSession = false;
      s.session = null;
      return { ...state, sso: s };
    }
  }

  return state;
};

const signInUrl = "/auth/sign_in";

export const actions = (dispatch: Dispatch<AppStateChange>) => {
  // loads SB session if exists
  const loadSession = (session = null) => {
    dispatch({ type: "SetSSOSession", value: session });
  };

  const signOut = async () => {
    await supabase.auth.signOut();
    dispatch({ type: "ClearSSOSession" });
    gotoSignIn();
  };

  const gotoSignIn = () => {
    let returnTo = encodeURIComponent(window.location.pathname);

    if (window.location.search.indexOf("code") > -1) {
      returnTo += window.location.search;
    }
    window.location.href = signInUrl + "?return_to=" + returnTo;
    return null;
  };
  const ensureSignIn = (session = null) => {
    if (session) {
      dispatch({ type: "SetSSOSession", value: session });
    } else if (window.location.hash === "") {
      gotoSignIn();
    }
  };

  return {
    loadSession,
    ensureSignIn,
    gotoSignIn,
    signOut,
  };
};
