import {
  AuthHeader,
  AuthHeaderFetcher,
  httpLink,
  scalarSerializers,
  tokenAuth,
  TokenAuth,
} from "@proca/api";
import { CombinedError } from "@urql/core";
import { Dispatch } from "react";
import { errorMessages } from "src/lib/helpers";
import { scalarLocations } from "src/proca-graphql";
import { AppState, AppStateChange, useAppState } from "src/state";
import createSerializeScalarsExchange from "urql-serialize-scalars-exchange";

export type { BasicAuth } from "@proca/api";
export class ProcaErrorException extends Error {
  error: CombinedError;
  constructor(error: CombinedError) {
    super(errorMessages(error));
    this.error = error;
    Object.setPrototypeOf(this, ProcaErrorException.prototype);
  }
}

// exchange/midldeware to auto-convert Json
const scalarSerializerExchange = createSerializeScalarsExchange(
  scalarLocations,
  scalarSerializers
);

export const reducer = (state: AppState, action: AppStateChange): AppState => {
  let s = state.proca;
  switch (action.type) {
    case "SetProcaError": {
      s = { ...s, error: action.value };
      return { ...state, proca: s };
    }
    case "SetSSOSession": {
      if (state.sso.hasSession) {
        s = {
          ...s,
          authenticated: true,
          client: makeClient(state.proca.apiUrl, {token: action.value?.access_token} || null),
        };
      }
      return { ...state, proca: s };
    }
    case "ClearSSOSession": {
      s = {
        ...s,
        authenticated: false,
        client: makeClient(state.proca.apiUrl),
      };
      return { ...state, proca: s };
    }
  }
  //XXX handle sign out
  return state;
};

export const actions = (dispatch: Dispatch<AppStateChange>) => {
  const setProcaError = (error: Error) =>
    dispatch({ type: "SetGeneralError", value: error });

  return {
    setProcaError,
  };
};

const makeClient = (apiUrl: string, auth?: TokenAuth) => {
  // this is just to be able to pass token directly in auth = {token: "ABC"}
  let authorization: AuthHeader;
  // this fetches authorization header from SB
  let fetchAuthorization: AuthHeaderFetcher;

  if (auth?.token) {
    authorization = tokenAuth(auth);
  } else {
    fetchAuthorization = () => auth?.token || null;
  }

  const client = httpLink(apiUrl, authorization || fetchAuthorization, {
    exchanges: [scalarSerializerExchange],
  });
  return client;
};

export const defaultState = (): AppState["proca"] => {
  const apiUrl =
    process.env["REACT_APP_API_URL"] || "https://api.proca.app/api";

  const client = makeClient(apiUrl);

  return {
    apiUrl,
    authenticated: false,
    client,
  };
};

/**
 * hook used in generated queries/mutations for Proca server.
 * Fetcher for react-query to use our authenticated URQL client (instead of banal fetch)
 * */
export const useUrqlFetcher = <TData, TVariables extends object>(
  query: string,
  _options?: RequestInit["headers"]
): ((variables?: TVariables) => Promise<TData>) => {
  const [
    {
      proca: { client },
    },
  ] = useAppState();

  // we need to figure out if this is query or mutation or subscription
  const qt = queryType(query);
  if (qt === "subscription")
    throw new Error("ReactQuery does not support subscriptions");
  const method = qt === "query" ? client.query : client.mutation;

  return async (variables?: TVariables) => {
    const { data, error } = await method(query, variables).toPromise();

    if (error) {
      throw error;
    }

    return data;
  };
};

function queryType(query: string): "query" | "mutation" | "subscription" {
  query = query.trim();
  if (query.indexOf("query") === 0) return "query";
  if (query.indexOf("mutation") === 0) return "mutation";
  if (query.indexOf("subscription") === 0) return "subscription";
  throw new Error(`Should not happen: GraphQL query of unknown type: ${query}`);
}
