/*
 * Component to store and provide the main app state.
 *
 * Access it using a hook:
 * const [appState, actions] = useAppState()
 * or
 * pass it down as prop (prop type AppState)
 *
 * Change the state using actions:
 * actions.foo()
 * or
 * send a message to state reducer using dispatch() - you must know the message format!
 *
 */

// Imports
import { KeyPair, KeyStore } from "@proca/crypto";
import { Client, CombinedError } from "@urql/core";
import {
  createContext,
  Dispatch,
  useContext,
  useMemo,
  useReducer,
  ReactNode,
  FC
} from "react";
import { SupabaseSession } from "src/lib/supabase";
import { UserInfoFragment } from "src/proca-graphql";
import * as nav from "./nav";
import * as other from "./other";
import * as proca from "./proca";
import * as sso from "./sso";
import SupabaseAuthListener from "./SupabaseAuthListener";
import * as user from "./user";

/* The main app state looks like this: */

export type AppState = {
  proca: {
    authenticated: boolean;
    apiUrl: string;
    client: Client;
    error?: CombinedError; // store unhandled API error (so we can warn the user)
  };
  sso: {
    hasSession: boolean;
    identityError?: any; // this error would only be set on some network exception - supabase.auth.session() will not return error per-se
    session: SupabaseSession;
  };
  crypto: {
    keyStore: KeyStore;
  };
  currentOrgName?: string;
  pageTitle: string;
  generalError?: Error; // Store unhandled general error (eg network is down)
};

/*
 * The initial app state values are (on page load):
 */

const defaultAppState: AppState = {
  sso: {
    hasSession: false,
    session:  JSON.parse(localStorage.getItem("sb-vurrrokassxubbxlvufw-auth-token")) || null
  },
  proca: proca.defaultState(),
  crypto: {
    keyStore: {
      filename: undefined,
      readFromFile: false,
      keys: [],
    },
  },
  currentOrgName: undefined,
  pageTitle: "Proca dashboard",
};

/*
 * The state is modified by reducer function which will react to a message:
 * The message can have following values:
 */

export type AppStateChange =
  | { type: "SetTitle"; value: string }
  | { type: "SetSSOSession"; value: SupabaseSession }
  | { type: "ClearSSOSession" }
  | { type: "SetProcaError"; value: CombinedError }
  | { type: "SetGeneralError"; value: Error }
  | { type: "AddUserRole"; value: UserInfoFragment["roles"][0] }
  | { type: "SetCurrentOrgName"; value: string }
  | { type: "AddToKeyStore"; value: KeyPair }
  | { type: "ImportToKeyStore"; value: any }
  | { type: "EmptyKeyStore" }
  | { type: "ClearKeyStore" };

// The complete actions list is the sum of actions from each 'state/*.ts' files:

export type AppActions = ReturnType<typeof sso.actions> &
  ReturnType<typeof user.actions> &
  ReturnType<typeof proca.actions> &
  ReturnType<typeof other.actions> &
  ReturnType<typeof nav.actions>;

// reducer of state

const reducers = [
  sso.reducer,
  user.reducer,
  proca.reducer,
  other.reducer,
  nav.reducer,
];

// run state, action through all the reducers
const allReducers = (state: AppState, action: AppStateChange) => {
  return reducers.reduce((s, r) => r(s, action), state);
};

export type AppStateActions = {
  state: AppState;
  actions: AppActions;
  dispatch?: Dispatch<AppStateChange>;
};

export interface AppProps {
  appState: AppState;
  actions: AppActions;
}

const AppContext = createContext<AppStateActions>({
  state: defaultAppState,
  actions: undefined,
  dispatch: undefined,
});

interface AppStateProviderProps {
  children: ReactNode;
}

const AppStateProvider: FC<AppStateProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(allReducers, defaultAppState);

  // useMemo caches the actions map - dispatch callback does not change (useReducer guarantee)
  const actions = useMemo(
    () => ({
      ...sso.actions(dispatch),
      ...user.actions(dispatch),
      ...proca.actions(dispatch),
      ...other.actions(dispatch),
      ...nav.actions(dispatch),
    }),
    [dispatch]
  );

  // Provider for app state and actions
  return (
    <AppContext.Provider value={{ state, actions, dispatch }}>
      <SupabaseAuthListener />
      {children}
    </AppContext.Provider>
  );
};

export const useAppState = (): [AppState, AppActions] => {
  const { state, actions } = useContext(AppContext);
  return [state, actions];
};

export default AppStateProvider;
