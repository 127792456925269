import { lazy, Suspense } from "react";
import { CookiesProvider } from "react-cookie";
import { Route, Routes } from "react-router-dom";
import LoadingScreen from "src/components/layout/LoadingScreen";
import Tools from "src/components/Tools";
import IdentityProvider from "src/components/user/IdentityProvider";
import SignIn from "src/components/user/SignIn";
import UserJoinOrg from "src/components/user/UserJoinOrg";
import UserProvider from "src/components/user/UserProvider";
import EntryFrame from "src/pages/EntryFrame";
import LoadErrorBoundary from "src/pages/LoadErrorBoundary";
import Zen from "src/pages/Zen";
import AppStateProvider from "src/state/AppState";

const AppLayout = lazy(() => import("./pages/AppLayout"));
const OnboardWizard = lazy(() => import("./components/onboarding/Wizard"));

function loading() {
  return (
    // XXX have some nice animation on loading - make the user feel it is working
    <LoadingScreen />
  );
}

const App = () => {
  return (
    <AppStateProvider>
      <CookiesProvider>
        <Suspense fallback={loading()}>
          <Routes>
            <Route path="/*"
              element={
                <LoadErrorBoundary>
                  <IdentityProvider>
                    <UserProvider>
                      <AppLayout />
                      <Tools />
                    </UserProvider>
                  </IdentityProvider>
                </LoadErrorBoundary>
              }
            />
            <Route path="auth"
              element={
                <LoadingScreen
                  title="Log in safely"
                  promo="Proudly powering progressive coalitions and nonprofit organisations' campaigns since 2020 with over 4M actions taken in 39 languages"
                  children={true}
                />} >
              <Route path="sign_in" element={<SignIn />} />
            </Route>
            <Route path="/zen" element={<Zen />}/>
            <Route path="/welcome/:campaign"
                element={
                  <OnboardWizard />
                }
            />
            <Route
              path="/join/:orgname"
              element={
                      <IdentityProvider>
                        <UserProvider>
                          <EntryFrame>
                            <UserJoinOrg />
                          </EntryFrame>
                        </UserProvider>
                      </IdentityProvider>
                                    }
            />
          </Routes>
        </Suspense>
      </CookiesProvider>
    </AppStateProvider>
  );
}

export default App;