import { FC, ReactNode } from "react";
import { useCurrentUserOrgsQuery } from "src/proca-graphql";
import { useAppState } from "src/state";

type Props = {
  children?: ReactNode;
};

const UserProvider: FC<Props> = (props) => {
  const [app, actions] = useAppState();

  const userQuery = useCurrentUserOrgsQuery(
    {},
    {
      enabled: app.sso.hasSession,
      onSuccess: (data) => {
        actions.determineOrg(data.currentUser);
      },
    }
  );

  if (!userQuery.isSuccess) return null;

  return <>{props.children}</>;
};

export default UserProvider;
