import { Dispatch } from "react";
import { AppState, AppStateChange } from "src/state";

export const reducer = (state: AppState, action: AppStateChange): AppState => {
  switch (action.type) {
    case "SetTitle":
      return { ...state, pageTitle: action.value };
  }
  return state;
};

export const actions = (dispatch: Dispatch<AppStateChange>) => {
  const setTitle = (title: string) =>
    dispatch({ type: "SetTitle", value: title });

  return {
    setTitle,
  };
};
