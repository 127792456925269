// console tools

import { FC, useEffect } from "react";
import useAppState from "src/hooks/useAppState";
import useUser from "src/hooks/useUser";
import { useResetApiTokenMutation } from "src/proca-graphql";

const Tools: FC = () => {
  const resetApiToken = useResetApiTokenMutation();
  const [, _a] = useAppState(); // no idea what it does, but needed
  const { user } = useUser(); // no idea what it does

  if (_a || user) {
  }

  useEffect(() => {
    Object.assign(window, {
      reset_api_token: async () => {
        resetApiToken.mutate(
          {},
          {
            onSuccess: (data) =>
              console.log(`Reset API token to ${data.resetApiToken}`),
          }
        );
      },
      whoami: async () => {
        console.log("user"); //user would de added to dependency, I don't know what it's there for
      },
    });
  }, [resetApiToken]);

  return null;
};

export default Tools;
