import { Snackbar } from "@mui/material";
import Alert from '@mui/material/Alert';
import { FC } from "react";
import useAppState from "src/hooks/useAppState";

const AppErrorBar: FC<{}> = () => {
  const [app] = useAppState();

  return (
    <>
      {app.proca.error && (
        <Snackbar open={Boolean(app.proca.error)} autoHideDuration={6000}>
          <Alert severity="error">
            Proca server replied: {app.proca.error.message}
          </Alert>
        </Snackbar>
      )}

      {app.generalError && (
        <Snackbar open={Boolean(app.generalError)} autoHideDuration={6000}>
          <Alert severity="error">Unexpected: {app.generalError.message}</Alert>
        </Snackbar>
      )}
    </>
  );
};

export default AppErrorBar;
