import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import titiliumRegularTTF from "src/fonts/TitilliumWeb-Regular.woff2";

const webfonts = [[titiliumRegularTTF, "regular", 400]].map(
  ([fontFile, style, weight]) => ({
    fontFamily: "Titilium",
    fontStyle: style,
    fontWeight: weight,
    src: `url(${fontFile}) format('woff2')`,
  })
);

export const brandColors = {
  red: "#ff5c39",
  blue: "#485cc7",
  gray: "#54585a",
  orange: "#ff5b3f",
  light: {
    blue: "#a4aee4",
    orange: "#ff7c60",
  },
};

const palette = {
  primary: {
    main: brandColors.gray,
    light: "#ff5c34",
    contrastText: "#ffffff",
  },
  secondary: {
    main: brandColors.blue,
    light: brandColors.light.blue,
    contrastText: "#ffffff",
  },
  error: {
    main: brandColors.red,
  },
};

const globalTheme = createTheme({
  palette,
  typography: {
    fontSize: 16,
    fontFamily: ["Titillium Web", "sans-serif"].join(","),
    h1: {
      fontWeight: 900,
      textTransform: "uppercase",
    },
    h2: {
      fontWeight: 600,
      textTransform: "uppercase",
    },
    h3: {
      fontWeight: 400,
      textTransform: "uppercase",
    },
    h4: {
      fontWeight: 400,
      textTransform: "uppercase",
    },
    h5: {
      fontWeight: 400,
    },
    h6: {
      fontWeight: 400,
    },
  },
  spacing: 8,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": webfonts,
        body: {
          background: "transparent!important",
          fontSize: 16,
          lineHeight: 1.43,
          letterSpacing: "0.01071em",
        },
        ".MuiAlert-root": {
          marginTop: 10,
          marginBottom: 10,
        },
        html: {
          backgroundImage: "linear-gradient(45deg,#ff5c39 0%,#485cc7)",
          backgroundSize: "200% 200%",
          minWidth: "100%",
          minHeight: "100%",
          animation: "gradient 9s ease infinite",
        },
        "@keyframes gradient": {
          "0%": {
            backgroundPosition: "0% 100%",
          },
          "50%": {
            backgroundPosition: "100% 50%",
          },
          "100%": {
            backgroundPosition: "0% 100%",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        labelIcon: {
          minHeight: 50,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          "&:hover": {
            backgroundColor: palette.primary.light,
            color: brandColors.gray,
          },
          fontWeight: 900,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        colorPrimary: {
          "&:hover": {
            color: "#ff5c34",
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#f8f8f8",
          borderBottom: `2px solid ${brandColors.gray}`,
          borderRadius: "2px",
          "&$error": {
            borderBottom: `2px solid ${brandColors.red}`,
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "filled",
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "filled",
      },
    },
  },
});

export const dashboardTheme = responsiveFontSizes(
  createTheme(globalTheme, {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          "@font-face": webfonts,
          body: {
            background: "transparent!important",
          },
        },
      },
    },
  })
);

export default responsiveFontSizes(globalTheme);
