import { addKeysToKeyStore, KeyStore, PublicKey, KeyPair } from "@proca/crypto";
import { Dispatch } from "react";
import { AppState, AppStateChange } from "src/state";

export const reducer = (state: AppState, action: AppStateChange): AppState => {
  //  console.info(`APP STATE ACTION ${action.type}`, action.value);
  switch (action.type) {
    case "SetGeneralError": {
      return { ...state, generalError: action.value };
    }
    case "AddToKeyStore": {
      const kc = state.crypto.keyStore;

      let found = false;
      const keys = kc.keys.map((k) => {
        if (k.public === action.value.public) {
          found = true;
          k.private = action.value.private;
        }
        return k;
      });
      if (!found) {
        keys.push(action.value);
      }

      return {
        ...state,
        crypto: { ...state.crypto, keyStore: Object.assign({}, kc, { keys }) },
      };
    }
    case "ClearKeyStore": {
      return {
        ...state,
        crypto: {
          ...state.crypto,
          keyStore: { ...state.crypto.keyStore, keys: [] },
        },
      };
    }
    case "ImportToKeyStore": {
      const localKc: KeyStore = {
        ...state.crypto.keyStore,
        keys: [...state.crypto.keyStore.keys],
      };

      addKeysToKeyStore(action.value, localKc);

      return { ...state, crypto: { ...state.crypto, keyStore: localKc } };
    }
  }
  return state;
};

export const actions = (dispatch: Dispatch<AppStateChange>) => {
  const setGeneralError = (error: Error) =>
    dispatch({ type: "SetGeneralError", value: error });

  const addToKeyStore = (key: PublicKey, priv: string) => {
    const keypair: KeyPair = { public: key.public, private: priv };
    dispatch({ type: "AddToKeyStore", value: keypair });
  };

  const clearKeyStore = () => dispatch({ type: "ClearKeyStore" });

  const mergeUploadedKeyFile = async (files: FileList) => {
    if (files.length === 0) return;
    const file = files[0];
    const text = await file.text();
    const k = fixLegacyKeysMap(JSON.parse(text));
    dispatch({ type: "ImportToKeyStore", value: k });
  };

  return {
    setGeneralError,
    addToKeyStore,
    clearKeyStore,
    mergeUploadedKeyFile,
  };
};

const fixLegacyKeysMap = (keys: any) => {
  const e = Object.entries(keys).map(([k, v]) => {
    if (v instanceof String) {
      return [k, { private: v }];
    } else {
      return [k, v];
    }
  });
  return Object.fromEntries(e);
};
