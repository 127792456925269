import { Box, Button } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import useAppState from "src/hooks/useAppState";
import { useAcceptInviteMutation, OrgFieldsFragment } from "src/proca-graphql";

interface Props {
  children?: any;
};

interface Problem extends Props {
  problem: string;
}
type Params = {
  code: string;
  email: string;
};

const UserJoinOrg: FC<Props> = (_props) => {
  const [, actions] = useAppState();
  const [org, setOrg] = useState<OrgFieldsFragment>();
  const [problem, setProblem] = useState<string>();

  const CampaignsLink: FC<{}> = () => {
    return (
      <Button variant="contained" color="primary" href="/campaign">
        See our campaigns!
      </Button>
    );
  };

  const JoinSuccess: FC<Props> = ({ children }) =>
    !org ? (
      <>{children}</>
    ) : (
      <Box>
        <h5>You joined the {org.title}</h5>
        <p>Welcome to the team!</p>
        <CampaignsLink />
      </Box>
    );



  const JoinFailure: FC<Problem> = ({ children, problem }) =>
    !problem ? (
      <>{children}</>
    ) : (
      <Box>
        <h5>We cannot add you - {problem}</h5>
      </Box>
    );

  const acceptInviteMutation = useAcceptInviteMutation();
  const AcceptInvite: FC<Params> = (params) => {
    useEffect(() => {
      acceptInviteMutation.mutate(params, {
        onError: (error) => {
          if (error.graphQLErrors[0].extensions.code === "expired") {
            setProblem("Link was already used");
          } else if (error.graphQLErrors[0].message === "staffer_exists") {
            setProblem("You are already in that team.");
          } else {
            setProblem(error.message);
          }
        },
        onSuccess: (data) => {
          const o = data.acceptUserConfirm.org;
          setOrg(o);
          actions.switchOrg(o.name);
        },
      });
    }, []);

    return null;
  };

  const CheckLink: FC<{}> = () => {
    const location = useLocation();
    const params: Params = useMemo(() => {
      const query = new URLSearchParams(location.search);
      return {
        code: query.get("code"),
        email: query.get("email"),
      };
    }, [location]);

    const invalid = !params.code || !params.email;

    useEffect(() => {
      if (invalid) setProblem("Link is invalid");
    }, [invalid]);

    if (invalid) {
      return null;
    } else {
      return <AcceptInvite {...params} />;
    }
  };

  return (
    <JoinSuccess>
        <JoinFailure problem={problem}>
          <CheckLink />
        </JoinFailure>
      </JoinSuccess>
  );
};

export default UserJoinOrg;
