import { useCallback } from "react";
import { queryClient } from "src/lib/server";
import { useCurrentUserOrgsQuery, OrgIdsFragment } from "src/proca-graphql";

const useUser = (errorBoundary=false) => {
  const userQuery = useCurrentUserOrgsQuery(
    {},
    {
      useErrorBoundary: errorBoundary,
    }
  );
  const user = userQuery.data?.currentUser;

  const getRoleIn = useCallback(
    (orgName: string) => user.roles.find((r) => r.org.name === orgName),
    [user]
  );

  const userHasOrg = user && user.roles.length !== 0;

  const userBecameOwner = useCallback(
    (ofOrg: OrgIdsFragment) => {
      if (getRoleIn(ofOrg.name) === undefined) {
        queryClient.invalidateQueries({ queryKey: ["CurrentUserOrgs"] });
      }
    },
    [getRoleIn]
  );

  return {
    user,
    userHasOrg,
    getRoleIn,
    userBecameOwner,
  };
};

export default useUser;
