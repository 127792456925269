import { FC, useEffect } from "react";
import useAppState from "src/hooks/useAppState";
import { supabase } from "src/lib/supabase";

const SupabaseAuthListener: FC = () => {
  const [, actions] = useAppState();

    useEffect(() => {
    // initial dispatch to set the state on page load.
    actions.loadSession();
    supabase.auth.onAuthStateChange((event, session) => {
      if (event === "INITIAL_SESSION" || "TOKEN_REFRESHED" || "SIGNED_IN") {
        actions.loadSession(session);
      }
    });
  }, [actions]);

  return null;
};

export default SupabaseAuthListener;
