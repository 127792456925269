import * as Sentry from "@sentry/browser";
import { Dispatch } from "react";
import { queryClient } from "src/lib/server";
import { UserInfoFragment } from "src/proca-graphql";
import { AppState, AppStateChange } from "src/state";

const orgNameForUser = (user: UserInfoFragment, current?: string) => {
  if (current && user.roles.find((r) => r.org.name === current)) {
    return current;
  }
  const saved = localStorage.getItem("orgName");
  if (saved && user.roles.find((r) => r.org.name === saved)) {
    return saved;
  }
  return user.roles[0]?.org?.name;
};

export const reducer = (state: AppState, action: AppStateChange): AppState => {
  let s = state;
  switch (action.type) {
    case "SetCurrentOrgName": {
      s = {
        ...state,
        currentOrgName: action.value,
      };

      localStorage.setItem("orgName", s.currentOrgName);

      return s;
    }
  }

  Sentry.setContext("org", { name: s.currentOrgName });
  return s;
};

export const actions = (dispatch: Dispatch<AppStateChange>) => {
  const switchOrg = (orgName: string) => {
    if (typeof orgName !== "string") {
      console.error("Tried to set org name to", orgName);
      throw Error(
        `You try to set app.state.currentOrgName to non-string: ${typeof orgName}`
      );
    }

    dispatch({ type: "SetCurrentOrgName", value: orgName });
    queryClient.invalidateQueries({ queryKey: ["CurrentUserOrgs"] });
  };

  const determineOrg = (user: UserInfoFragment) => {
    const orgName = orgNameForUser(user, undefined);
    if (orgName) {
      dispatch({ type: "SetCurrentOrgName", value: orgName });
    }
  };

  return {
    switchOrg,
    determineOrg,
  };
};
